const NotFound = () => {
  return (
    <div>
      <div>
        <div style={{ height: '80px', color: '#fff' }}></div>
        <div
          style={{
            overflow: 'auto',
            backgroundColor: '#e6e6e6',
            height: '100vh'
          }}
        >
          <div>This Page Doesn't exist</div>
        </div>
      </div>
    </div>
  )
}
export default NotFound
