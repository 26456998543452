const Home = () => {
  return (
    <div style={{ overflow: 'hidden', height: '100%' }}>
      <iframe
        title='home'
        src='https://pam-taylor.kw.com/'
        style={{
          height: window.innerHeight,
          width: '100%',
          border: '0px'
        }}
      ></iframe>
    </div>
  )
}
export default Home
